<template>
  <div>
    <div :class="[spaced]" class="flex items-center">
      <hr class="flex-grow border-slate-300">
      <div class="mx-4 max-w-[80%] uppercase font-bold text-slate-600">
        <slot />
      </div>
      <hr class="flex-grow border-t-slate-300">
    </div>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  noSpacing?: boolean,
}
const props = defineProps<Props>()
const spaced = props.noSpacing ? '' : 'mb-6 sm:mb-16'
</script>
